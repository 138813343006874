import Vue from 'vue'
import VueRouter from 'vue-router'
// import Homepage from '@/views/Homepage.vue'
// import login from '@/views/login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
// import pds1 from '@/views/pds1.vue'
// import pds3 from '@/views/pds3.vue'
// import pds7 from '@/views/pds7.vue'

Vue.use(VueRouter)

const routes = [
  // { path: '/login', name: 'login', component: login },
  { path: '/forgotpassword', name: 'forgot', component: forgotPassword },
  // {
  //   path: '/',
  //   component: Homepage,
  //   children: [
  //   { path: '/', name: 'table', component: () => import('@/views/table') },
  //   { path: '/showallnews', name: 'showallnews', component: () => import('@/views/showallnews') },
  //   { path: '/addnews', name: 'addnews', component: () => import('@/views/addnews') },
  //   { path: '/pds1', name: 'pds1', component: () => import('@/views/pds1') },
  //   { path: '/pds3', name: 'pds3', component: () => import('@/views/pds3') },
  //   { path: '/pds7', name: 'pds7', component: () => import('@/views/pds7') },
  //   { path: '/viewallpds1', name: 'viewallpds1', component: () => import('@/views/viewallpds1') },
  //   { path: '/viewallpds3', name: 'viewallpds3', component: () => import('@/views/viewallpds3') },
  //   { path: '/viewallpds7', name: 'viewallpds7', component: () => import('@/views/viewallpds7') },
  //   { path: '/requestDocument', name: 'requestDocument', component: () => import('@/views/requestDocument') },
  //   { path: '/uploadcsv', name: 'uploadcsv', component: () => import('@/views/uploadcsv') },

    
    
    
     
  //   ]
  // }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
